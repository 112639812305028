
  import { Component, Mixins } from "vue-property-decorator";
  import { mapState } from "vuex";
  import InfoModal from "@/components/InfoModal.vue";
  import { drawPieChart, PieChartColors } from "@/assets/js/pie-chart";
  import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";
  import SchoolMixin from "@/mixins/school.mixin";

  @Component({
    components: { InfoModal },
    computed: { ...mapState("app", ["mobileView", "tabletView"]) },
    metaInfo: { title: "About Page" }
  })

  export default class TeacherWorkforce extends Mixins(ScrollToTopMixin, SchoolMixin) {
    mobileView!: boolean;
    tabletView!: boolean;
    public pielabelObj: string[] = [];
    public PieDataObj: number[] = [];
    NatAverageTeacherAnnAttdRate = 94;
    givenTitle = "";
    givenDescription = "";
    pieChartColors = PieChartColors;
    viewCertTeacherSchoolInfoSideBar = false;
    viewCertTeacherSchoolDetailsSideBar = false;
    viewRetentionSchoolInfoSideBar = false;
    viewRetentionSchoolDetailsSideBar = false;
    viewAttendanceSchoolInfoSideBar = false;
    viewAttendanceSchoolDetailsSideBar = false;
    viewEarlyLiteracySchoolInfoSideBar = false;
    viewEarlyLiteracySchoolDetailsSideBar = false;
  
    mounted() {
      console.log(this.school);
      this.generatepiechart();
    }
  
    generatepiechart() {
      const Pielabels = [] as any;
      const PieDatas = [] as any;

      if(this.school.teacherWorkforce.raceSchoolBlack && Number(this.school.teacherWorkforce.raceSchoolBlack)>0)
      {
            Pielabels.push("African American");
            PieDatas.push(Number(this.school.teacherWorkforce.raceSchoolBlack));
      }

      if(this.school.teacherWorkforce.raceSchoolHispanic && Number(this.school.teacherWorkforce.raceSchoolHispanic)>0)
      {
            Pielabels.push("Hispanic");
            PieDatas.push(Number(this.school.teacherWorkforce.raceSchoolHispanic));
      }

      if(this.school.teacherWorkforce.raceSchoolMultiple && Number(this.school.teacherWorkforce.raceSchoolMultiple)>0)
      {
            Pielabels.push("Multiple Races / Ethnicities");
            PieDatas.push(Number(this.school.teacherWorkforce.raceSchoolMultiple));
      }
      
      if(this.school.teacherWorkforce.raceSchoolNativeAmerican && Number(this.school.teacherWorkforce.raceSchoolNativeAmerican)>0)
      {
              Pielabels.push("Native American");
              PieDatas.push(Number(this.school.teacherWorkforce.raceSchoolNativeAmerican));
      }
      
      if(this.school.teacherWorkforce.raceSchoolWhite && Number(this.school.teacherWorkforce.raceSchoolWhite)>0)
      {
              Pielabels.push("White");
              PieDatas.push(Number(this.school.teacherWorkforce.raceSchoolWhite));
      }

      if(this.school.teacherWorkforce.raceSchoolPacificIslander && Number(this.school.teacherWorkforce.raceSchoolPacificIslander)>0)
      {
              Pielabels.push("Pacific Islander");
              PieDatas.push(Number(this.school.teacherWorkforce.raceSchoolPacificIslander));
      }
      
      if(this.school.teacherWorkforce.raceSchoolAsian && Number(this.school.teacherWorkforce.raceSchoolAsian)>0)
      {
              Pielabels.push("Asian");
              PieDatas.push(Number(this.school.teacherWorkforce.raceSchoolAsian));
      }
      
      this.pielabelObj = Pielabels;
      this.PieDataObj = PieDatas;

      /*
          this.PieDataObj = PieDatas.filter((item,index) => {
          if(item && parseInt(item) != 0){
            return item;
          }else {
            this.pielabelObj.splice(index,1)
            return false;
          } 
          });
      */

      drawPieChart("pieChart", this.pielabelObj, this.PieDataObj);
    }

    calcCharIndex(index: any) {
      return String.fromCharCode(index + 1 + 64);
    }

    get sidebarWidth(): string {
      let result = "";
      if (this.mobileView) {
        result = "100%";
      } else if (this.tabletView) result = "40%";
      return result;
    }

    get customLayout() {
      return {
        col: this.tabletView,
        "": this.mobileView,
      };
    }

    viewSideBar(sideBar: string) {
      switch (sideBar) {
        case "certTeacherSchoolInfo":
          this.viewCertTeacherSchoolInfoSideBar = true;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;

        case "certTeacherSchoolDetails":
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = true;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;

        case "retentionSchoolInfo":
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = true;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;

        case "retentionSchoolDetails":
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = true;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;

        case "attendanceSchoolInfo":
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = true;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;

        case "attendanceSchoolDetails":
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = true;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;

        case "earlyLiteracySchoolInfo":
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = true;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;

        case "earlyLiteracySchoolDetails":
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = true;
          break;

        default:
          this.viewCertTeacherSchoolInfoSideBar = false;
          this.viewCertTeacherSchoolDetailsSideBar = false;
          this.viewRetentionSchoolInfoSideBar = false;
          this.viewRetentionSchoolDetailsSideBar = false;
          this.viewAttendanceSchoolInfoSideBar = false;
          this.viewAttendanceSchoolDetailsSideBar = false;
          this.viewEarlyLiteracySchoolInfoSideBar = false;
          this.viewEarlyLiteracySchoolDetailsSideBar = false;
          break;
      }
    }
  }
